import request from '@/utils/request';
import {LoginDto} from '@/framework/models/LoginDto';

const url = `/account/`;

export function login(loginInfo: LoginDto): any {
  return new Promise<any>((resolve, reject) => {
    request({
      url: `${url}signin`,
      method: 'post',
      data: loginInfo
    }).then((res) => resolve(res.data)).catch((error) => reject(error));
  });
}
